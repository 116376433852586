import { Addresses } from '@/domain/addresses';
import { getChainConstant } from '@/domain/chain';
import useChainId from '@/hooks/useChainId';
import VeFloorStakingAbi from '@/infrastructure/abis/VeFloorStakingAbi';
import { Address } from 'viem';
import { useReadContract } from 'wagmi';

const useSweepPower = ({
  address,
  watch,
}: {
  address?: Address;
  watch?: boolean;
}) => {
  const chainId = useChainId();

  return useReadContract({
    address: getChainConstant(Addresses, chainId, 'VeFloorStaking'),
    abi: VeFloorStakingAbi,
    functionName: 'votingPowerOf',
    args: [address as Address],
    query: {
      enabled: !!address,
      refetchInterval: watch ? 15000 : false,
    },
  });
};

export default useSweepPower;
