import { Addresses } from '@/domain/addresses';
import { getChainConstant } from '@/domain/chain';
import useChainId from '@/hooks/useChainId';
import TreasuryAbi from '@/infrastructure/abis/TreasuryAbi';
import { useReadContract } from 'wagmi';

const useMinSweepAmount = () => {
  const chainId = useChainId();
  return useReadContract({
    abi: TreasuryAbi,
    functionName: 'minSweepAmount',
    address: getChainConstant(Addresses, chainId, 'Treasury'),
  });
};

export default useMinSweepAmount;
