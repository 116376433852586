import { Strategy } from '@/core/strategy';
import { getChainConstant } from '@/domain/chain';
import { EXCLUDED_STRATEGIES } from '@/domain/strategies';
import { SubgraphUrl } from '@/domain/subgraph';
import useChainId from '@/hooks/useChainId';
import querySubgraph from '@/infrastructure/subgraph';
import { useQuery } from '@tanstack/react-query';

const useStrategies = () => {
  const chainId = useChainId();

  return useQuery({
    queryKey: ['strategies', chainId],
    queryFn: async () => {
      const data = await querySubgraph<{ strategies: Strategy[] }>({
        url: getChainConstant(SubgraphUrl, chainId),
        query: `query Strategies {
          strategies(where: { id_not_in: ${JSON.stringify(EXCLUDED_STRATEGIES)}}) {
            id
            name
            cumulativeRewards
            tokens {
              id
              symbol
            }
            activity {
              type
              amount
              timestamp
              token {
                symbol
                name
                id
              }
            }
            collection {
              id
              name
              symbol
            }
            active
            harvest {
              id
              epoch {
                id
              }
              token {
                symbol
                id
              }
              amount
            }
          }
        }`,
      });

      return data?.strategies || [];
    },
  });
};

export default useStrategies;
