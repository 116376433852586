import { ellipsis } from '@/utils/ellipsis';
import { Address } from 'viem';
import { useEnsName } from 'wagmi';

const User = ({ address }: { address?: Address }) => {
  const { data: name } = useEnsName({ address });

  return name || ellipsis(address || '');
};

export default User;
