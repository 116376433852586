import { Epochs } from '@/core/epoch';
import { getChainConstant } from '@/domain/chain';
import { SubgraphUrl } from '@/domain/subgraph';
import useChainId from '@/hooks/useChainId';
import querySubgraph from '@/infrastructure/subgraph';
import { useQuery } from '@tanstack/react-query';

const useEpochs = () => {
  const chainId = useChainId();

  return useQuery({
    queryKey: ['epochs', chainId],
    queryFn: async () => {
      const data = await querySubgraph({
        url: getChainConstant(SubgraphUrl, chainId),
        query: `query Epochs {
            epoches(where: { id_gt: 0, startTime_not: null } orderBy: startTime orderDirection: desc) {
              id,
              type,
              sweep {
                sweepSize
                status
              }
              collections {
                id
                votesFor
                votesAgainst
                collection {
                  id
                  name
                  symbol
                }
              }
              newCollections {
                id
                votesFor
                collection {
                  id
                  name
                  symbol
                }
              }
              sampleSize
              startTime,
              endTime
            }
          }`,
      });

      return (data?.epoches || []) as Epochs;
    },
  });
};

export default useEpochs;
