import { usePublicClient } from 'wagmi';
import StrategyFactoryAbi from '@/infrastructure/abis/StrategyFactoryAbi';
import { getChainConstant } from '@/domain/chain';
import useChainId from '@/hooks/useChainId';
import { Addresses } from '@/domain/addresses';
import { useQuery } from '@tanstack/react-query';
import useStrategies from '../strategies/useStrategies';
import { Address } from 'viem';

const useCurrentEpochYield = () => {
  const chainId = useChainId();
  const { data: strategies } = useStrategies();

  // console.log(strategies);
  const client = usePublicClient();
  return useQuery({
    queryKey: ['currentEpochYield', chainId],
    queryFn: async () => {
      const call = await client?.simulateContract({
        abi: StrategyFactoryAbi,
        account: getChainConstant(Addresses, chainId, 'FloorDeployer'),
        address: getChainConstant(Addresses, chainId, 'StrategyFactory'),
        functionName: 'snapshot',
        args: [0n],
      });

      return call?.result as [
        strategyAddress: Address[],
        strategyYield: bigint[],
        totalYield: bigint,
      ];
    },
    // refetchInterval: 15000,
    // cacheTime 30minutes (1800000ms)
    gcTime: 1800000,
    enabled: !!strategies && !!client,
  });
};

export default useCurrentEpochYield;
