import { Addresses } from '@/domain/addresses';
import { getChainConstant } from '@/domain/chain';
import useChainId from '@/hooks/useChainId';
import EpochManagerAbi from '@/infrastructure/abis/EpochManagerAbi';
import { useReadContract } from 'wagmi';

const useCurrentEpoch = () => {
  const chainId = useChainId();

  return useReadContract({
    abi: EpochManagerAbi,
    address: getChainConstant(Addresses, chainId, 'EpochManager'),
    functionName: 'currentEpoch',
  });
};

export default useCurrentEpoch;
