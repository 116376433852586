import React, { useEffect, useRef, useState } from 'react';
import { renderIcon } from '@download/blockies';

const BlockieIdenticon = ({
  address,
  diameter = 32,
  className,
}: {
  address: string;
  diameter?: number;
  className?: any;
}) => {
  const [dataUrl, setDataUrl] = useState<string>();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (address.length && canvasRef.current) {
      const canvas = canvasRef.current;
      renderIcon({ seed: address?.toLowerCase() }, canvas);
      const updatedDataUrl = canvas.toDataURL();

      if (updatedDataUrl !== dataUrl) {
        setDataUrl(updatedDataUrl);
      }
    }
  }, [dataUrl, address, canvasRef]);

  if (!address) {
    return (
      <span
        style={{ width: diameter, height: diameter }}
        className="animate-pulse bg-gray-700 rounded-sm"
      />
    );
  }

  return (
    <>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      <img
        src={dataUrl}
        height={diameter}
        width={diameter}
        alt="blockie"
        className={`rounded-sm inline-flex flex-none ${className ?? ''}`}
      />
    </>
  );
};

export default BlockieIdenticon;
